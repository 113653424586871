import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Observable } from "rxjs";
import { environment } from "libs/environment";
@Injectable()
export class TrackingComponentService {
    constructor(
        private httpService: HttpService
      ) { }
    getComponentList(controlPointNumber: number): Observable<string[]> {
        return this.httpService.Get<string[]>(`${environment.baseUrl}/api/tracking-component/${controlPointNumber}/list`);
    }
    getComponentStatus(sdpJobId: number, trackingComponent: string): Observable<string> {
        return this.httpService.Get<string>(`${environment.baseUrl}/api/tracking-component/${sdpJobId}/status/${trackingComponent}`);
    }
    getSDPJobInfoStatus(sdpJobId:number): Observable<any[]> {
        return this.httpService.Get<any[]>(`${environment.baseUrl}/api/tracking-component/${sdpJobId}/status/job-info`);
    }
}
import { PumpSchedule } from './pump-schedule';
import {
  FluidModel,
  CDIIcem,
  CentralizerSpecification,
  CentralizationInterval,
  AccountRepresentative,
  JobCasingEquipmentParameters,
  CompatibilityConfirmation,
  CDIIcemVariable,
} from 'libs/models';
import { ControlPointModel, CpStatuses } from 'apps/vida/src/modules/control-point/models';
import { Role } from '../../../apps/vida/src/modules/application/models';
import { CDIOverview } from './cdi-overview';
import { Lesson } from './lesson';
import { JobCasingEquipment } from './job-casing-equipment';
import { WellboreGeometryInputsModel } from '../wellboreGeometry/WellboreGeometryInputsModel';
import { ActualCO2Emissions, PlannedCO2Emissions } from 'apps/vida/src/modules/control-point/models/CO2Emissions';
import {
  InnerGeometry,
  InnerStringGeometry,
  OuterGeometry
} from '../../../apps/vida/src/modules/wellbore/models/geometry';

export class Job {
  id: string;
  actualDate: string;
  createdDate: string;
  createdDateUTC: string;
  isManualActualDate: boolean;
  bomType: string;
  bomTypeNumber: string;
  bomTypeDescription: string;
  isCementingBomType: boolean;
  allowNonCementingSalesOrder: boolean;
  nonCementingSalesOrderComment: string;
  contactFullName: string;
  contactAddress1: string;
  contactAddress2: string;
  contactCity: string;
  contactCountry: string;
  contactEmail: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhoneNumber: string;
  customer: string;
  customerName: string;
  parentCustomerName: string;
  deviationStatus: number;
  deviationStatusDescription: string;
  field: string;
  groupId: string;
  group: string;
  groupName: string;
  regionCode: string;
  isDraftMode: boolean;
  isFoam: boolean;
  isFoamManuallyChecked: boolean;
  riskLevel: number;
  isOffshore: boolean;
  isTightHole: boolean;
  actualJobMd: number;
  jobMd: number;
  jobName: string;
  jobCode: string;
  jobOwner: string;
  jobOwnerNetworkId: string;
  jobStatus: string;
  jobStatusDescription: string;
  jobTvd: number;
  isBHCTConfirmed: boolean;
  jobType: string;
  jobTypeName: string;
  isLiner: boolean;
  isCasingEquipment: boolean;
  withPlug: boolean;
  plugBaseId: number;
  latitudeDegrees: number;
  latitudeDirection: string;
  latitudeMinutes: number;
  latitudeSeconds: string;
  lease: string;
  longitudeDegrees: number;
  longitudeDirection: string;
  longitudeMinutes: number;
  longitudeSeconds: string;
  postalCode: string;
  projectedDate: string;
  proposalNumber: string;
  quoteNumber: string;
  rig: number;
  rigName: string;
  salesOrderNumber: string;
  salesOrderLineItem: string;
  profitCenter: string;
  ceSalesOrderNumber: string;
  ceSoIsPrimarySo: boolean;
  shipToLocation: string;
  shipToNumber: string;
  site: string;
  stateProvinceRegion: string;
  waterDepth: number;
  casingSizeValue: number;
  holeSizeValue: number;
  wellApiUwi: string;
  wellDatumElevation: number;
  wellId: string;
  wellName: string;
  wellNumber: string;
  isLand: boolean;
  isDeviated: boolean;
  isVeritical: boolean;
  isStageJob: boolean;
  countryCode: string;
  canEdit: boolean;
  editMode: boolean;
  inLibrary: boolean;
  isMyJob: boolean;
  jobCountryId: string;
  contractId: string;
  countryName: string;
  countryCustomerName: string;
  casingSize: string;
  holeSize: string;
  originalCasingSize?: string;
  originalHoleSize?: string;
  originalJobMd?: string;
  originalJobTvd?: string;
  bhst: number;
  bhstSource: number;
  bhstReasonOfOther: string;
  bhct: number;
  bhctSource: number;
  bhctReasonOfOther: string;
  bhp: number;
  bhpSource: number;
  bhpReasonOfOther: string;
  listRequestByJob: any;
  hasNewMessage: boolean;
  slurry: FluidModel[];
  includeManualSlurries: boolean;
  pumpSchedules: PumpSchedule[];
  isImportDataFromiCem: boolean;
  isGeothermal: boolean;
  isGeothermalManuallyChecked: boolean;
  isRemedial: boolean;
  isRemedialManuallyChecked: boolean;
  isJobLogExists: boolean;
  otherSpecificationToNote: string;
  cdiIcem: CDIIcem[];
  cdiIcemVariables: CDIIcemVariable[];
  actualTemperatureInputMethod: string;
  recommendedTemperatureInputMethods: string;
  cdiControlPoint: CDIOverview[];
  controlPoints: ControlPointModel[];
  hdfJobId: string;
  isJobDateAlert: boolean;
  extractedSoFlag: boolean;
  extractHdfGeometryKey: string;
  isManualBHST: boolean;
  isManualHoleSizeValue: boolean;
  isManualCasingSizeValue: boolean;
  isManualBHCT: boolean;
  originalJobId: string;
  originalJobCode: string;
  originalJobName: string;
  originalJobCp1Approved: boolean;
  isClonedJob: boolean;
  wellMasterNumber: string;
  roles: Role[];
  isUsingEquipment: boolean;
  holeSize2: number;
  holeSize3: number;
  holeSizeExcess: number;
  holeSizeExcess2: number;
  holeSizeExcess3: number;
  crossoverDepth2: number;
  crossoverDepth3: number;
  casingSize2: number;
  casingSize3: number;
  lengthOfPlug: number;
  criticalWellNumber: string;
  reviewCompletionStatement: boolean;
  maxPressure: number;
  isManualMaxPressure: boolean;
  isImpactLCMConsidered: boolean;
  impactLCMComments: string;
  isCasingStretchSimulated?: boolean;
  casingStretchComments: string;
  casingStretch: number;
  ratholeLength: number;
  taggingBottom: boolean;
  casingStretchMoc: string;
  casingStretchComment: string;
  casingStretchIfNotTaggingBottom: number;
  casingStretchIfTaggingBottom: number;
  mudRemoval: number;
  isManualRatholeLength: boolean;
  minHydrostaticVsPorePressure: number;
  countryStateId: number;
  rigLocationTypeId: number;

  isJobLogFileExists: boolean;
  cpStatuses: CpStatuses;
  isKpiOptional: boolean;

  isJobCreateFromWell?: boolean;

  standoffCalculationMethod: string;
  centralizationInterval: CentralizationInterval[];
  centralizerSpecification: CentralizerSpecification[];
  accountRepresentative: AccountRepresentative;
  jobCasingEquipment: JobCasingEquipment[];
  jobCasingEquipmentParameters: JobCasingEquipmentParameters;
  compatibilityConfirmation: CompatibilityConfirmation[];
  lesson: Lesson[];
  lessonsUpdatedDate: string;
  isLessonsPulled: boolean;

  cmtSvc: boolean;
  ceSvc: boolean;
  ceDirectSales: boolean;
  plantCode: string;

  hasObjectives: boolean;
  firstStageMudWeight: number;
  pp: number;
  ppTVD: number;
  ppDensity: number;
  ppMudPressure: number;
  ppMudDensity: number;
  fgTVD: number;
  fgPressure: number;
  fgDensity: number;
  fgMudPressure: number;
  fgMudDensity: number;
  baData: string;
  canChangeJobOwner: boolean;
  appianPushState: number;
  appianBBLoadStatus: number;

  wellboreGeopetryInformation: WellboreGeometryInputsModel[];
  wellboreGeopetryInformationIndex:number;
  //Well Job
  actualCO2Emissions: ActualCO2Emissions;
  plannedCO2Emissions: PlannedCO2Emissions;
  createMethod: string;

  // Well Job
  topOfCasingLiner: number;
  bottomOfCasingLiner: number;
  casingLinerContactFrictionFactor: number;
  bottomOfHole: number;
  holeContactFrictionFactor: number;
  casingSizeId: number;
  casingSizeOd: number;
  draftMode: boolean;
  mudWeight: number;
  slurryDensityLead: number;
  slurryDensityTail: number;
  jobDate: string;
  isRemedialWorkRequired: boolean;
  remedialWorkRequiredComments: string;
  /**
   * Wellbore Segment
   */
  innerGeometry: InnerGeometry[] = [];
  innerStringGeometry: InnerStringGeometry[] = [];
  outerGeometry: OuterGeometry[] = [];
  sdpProjectId: number;
  /**
   *
   */
  constructor() {
    this.id = '';
    this.bomType = null;
    this.bomTypeDescription = '';
    this.bomTypeNumber = '';
    this.isCementingBomType = false;
    this.allowNonCementingSalesOrder = false;
    this.nonCementingSalesOrderComment = '';
    this.contactAddress1 = '';
    this.contactAddress2 = '';
    this.contactCity = '';
    this.contactCountry = '';
    this.contactEmail = '';
    this.contactFirstName = '';
    this.contactLastName = '';
    this.contactFullName = '';
    this.contactPhoneNumber = '';
    this.customer = '';
    this.customerName = '';
    this.deviationStatus = null;
    this.deviationStatusDescription = '';
    this.field = '';
    this.group = '';
    this.groupName = '';
    this.regionCode = '';
    this.isFoam = false;
    this.isFoamManuallyChecked = false;
    this.riskLevel = 3;
    this.isOffshore = false;
    this.isTightHole = false;
    this.jobMd = null;
    this.jobName = '';
    this.jobCode = '';
    this.jobOwner = '';
    this.jobStatus = null;
    this.jobStatusDescription = '';
    this.jobTvd = null;
    this.jobType = '';
    this.jobTypeName = '';
    this.latitudeDegrees = null;
    this.latitudeDirection = null;
    this.latitudeMinutes = null;
    this.latitudeSeconds = '';
    this.lease = '';
    this.longitudeDegrees = null;
    this.longitudeDirection = null;
    this.longitudeMinutes = null;
    this.longitudeSeconds = '';
    this.postalCode = '';
    this.projectedDate = '';
    this.actualDate = '';
    this.isManualActualDate = false;
    this.proposalNumber = '';
    this.plugBaseId = null;
    this.quoteNumber = '';
    this.rig = null;
    this.rigName = '';
    this.salesOrderNumber = '';
    this.salesOrderLineItem = null;
    this.profitCenter = null;
    this.ceSalesOrderNumber = '';
    this.ceSoIsPrimarySo = false;
    this.shipToLocation = '';
    this.shipToNumber = '';
    this.site = '';
    this.stateProvinceRegion = '';
    this.waterDepth = null;
    // this.casingSizeId = null;
    // this.holeSizeId = null;
    this.casingSizeValue = null;
    this.holeSizeValue = null;
    this.wellApiUwi = '';
    this.wellDatumElevation = null;
    this.wellId = '';
    this.wellName = '';
    this.wellNumber = '';
    this.isLand = true;
    this.isDeviated = false;
    this.isVeritical = true;
    this.isStageJob = false;
    this.countryCode = '';
    this.canEdit = true;
    this.editMode = false;
    this.jobCountryId = 'USA';
    this.contractId = null;
    this.casingSize = '';
    this.holeSize = '';
    this.bhct = null;
    this.bhctSource = null;
    this.bhctReasonOfOther = '';
    this.bhst = null;
    this.bhstSource = null;
    this.bhstReasonOfOther = '';
    this.bhp = null;
    this.bhpSource = null;
    this.bhpReasonOfOther = '';
    this.pumpSchedules = null;
    this.includeManualSlurries = false;
    this.isImportDataFromiCem = false;
    this.isGeothermal = false;
    this.isGeothermalManuallyChecked = false;
    this.isRemedial = false;
    this.isRemedialManuallyChecked = false;
    this.otherSpecificationToNote = '';
    this.cdiIcem = null;
    this.cdiControlPoint = null;
    this.hdfJobId = '';
    this.isJobDateAlert = false;
    this.extractedSoFlag = false;
    this.extractHdfGeometryKey = '';
    this.isManualBHST = false;
    this.isManualHoleSizeValue = false;
    this.isManualCasingSizeValue = false;
    this.isManualBHCT = false;
    this.originalJobId = '';
    this.originalJobCode = '';
    this.originalJobName = '';
    this.originalJobCp1Approved = false;
    this.isClonedJob = false;
    this.wellMasterNumber = '';
    this.roles = null;
    this.isUsingEquipment = false;
    this.holeSize2 = null;
    this.holeSize3 = null;
    this.holeSizeExcess = null;
    this.holeSizeExcess2 = null;
    this.holeSizeExcess3 = null;
    this.crossoverDepth2 = null;
    this.crossoverDepth3 = null;
    this.casingSize2 = null;
    this.casingSize3 = null;
    this.lengthOfPlug = null;
    this.criticalWellNumber = '';
    this.reviewCompletionStatement = false;
    this.isJobLogFileExists = null; //not defined
    this.cmtSvc = true;
    this.ceSvc = false;
    this.ceDirectSales = false;
    this.plantCode = null;
    this.accountRepresentative = null;
    this.maxPressure = null;
    this.isManualMaxPressure = false;
    this.isCasingStretchSimulated = null;
    this.casingStretchComments=null;
    this.isJobCreateFromWell = null;
    this.countryStateId = null;
    this.rigLocationTypeId = null;

    this.hasObjectives = false;
    this.firstStageMudWeight = null;
    this.firstStageMudWeight = null;
    this.pp = null;
    this.ppTVD = null;
    this.ppDensity = null;
    this.ppMudPressure = null;
    this.ppMudDensity = null;
    this.fgTVD = null;
    this.fgPressure = null;
    this.fgDensity = null;
    this.fgMudPressure = null;
    this.fgMudDensity = null;
    this.jobCasingEquipment = [];
    this.jobCasingEquipmentParameters = null;
    this.compatibilityConfirmation = [];
    this.canChangeJobOwner = false;
    this.createMethod = undefined;

    //Well job
    this.topOfCasingLiner = null;
    this.bottomOfCasingLiner= null;
    this.casingLinerContactFrictionFactor= null;
    this.bottomOfHole= null;
    this.holeContactFrictionFactor= null;
    this.casingSizeId= null;
    this.casingSizeOd= null;
    this.draftMode = false;
    this.mudWeight = null;
    this.slurryDensityLead = null;
    this.slurryDensityTail = null;
    this.jobDate = null;
    this.wellboreGeopetryInformation = [];
    this.wellboreGeopetryInformationIndex=null;

    this.actualCO2Emissions = null;
    this.isManualRatholeLength = false;
    this.mudRemoval = null;
    this.appianPushState = 0;
    this.appianBBLoadStatus = 0;
    this.sdpProjectId = null;
  }

  public static fromData(data: any) {
    return { ...data };
  }

  public static isValidNumber(value: string | number) {
    if (typeof value == 'string') {
      return false;
    }

    return value != null && value != undefined;
  }
}


export class ChildParentJobModel {
  childId: string;
  parentId: string;
  hostUrl: string;

  constructor(childId, parentId, hostUrl) {
    this.childId = childId;
    this.parentId = parentId;
    this.hostUrl = hostUrl;
  }
}










